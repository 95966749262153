import { MarketId } from '../constants/markets'
import {
  DailySnapshot,
  DailySnapshotTradingData,
  EMPTY_DAILY_TRADING_SNAPSHOT,
  StatsInstrumentFilter,
  StatsInstrumentType,
  StatsIntervalFilter,
  StatsMarketFilter,
} from '../constants/stats'
import { deepCopy } from './array'
import { getUtcMs } from './time'

// Helper to get the start of the month
const getStartOfMonth = (date: Date): Date => {
  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1, 0, 0, 0, 0))
}

// Helper to get the start of the week (Sunday)
const getStartOfWeek = (date: Date): Date => {
  const day = date.getUTCDay() // 0 is Sunday
  const diff = date.getUTCDate() - day // Get the difference to move to the previous Sunday
  const startOfWeek = new Date(
    Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), diff, 0, 0, 0, 0)
  )
  return startOfWeek
}

// Main function to group snapshots by the start of the month or week
export const groupDailySnapshots = (
  snapshots: DailySnapshot[],
  groupBy: StatsIntervalFilter
): DailySnapshot[] => {
  if (groupBy === StatsIntervalFilter.DAILY) {
    return snapshots // already daily
  }

  const groupedSnapshots: { [key: string]: DailySnapshot[] } = {}

  snapshots.forEach((snapshot) => {
    const date = new Date(snapshot.timestamp)

    let groupKey: number
    if (groupBy === StatsIntervalFilter.MONTHLY) {
      groupKey = getUtcMs(getStartOfMonth(date))
    } else {
      // weekly
      groupKey = getUtcMs(getStartOfWeek(date))
    }

    if (!groupedSnapshots[groupKey]) {
      groupedSnapshots[groupKey] = []
    }

    groupedSnapshots[groupKey].push(snapshot)
  })

  return Object.entries(groupedSnapshots).map(([timestamp, snapshots]) => {
    const aggregateTrading = deepCopy(EMPTY_DAILY_TRADING_SNAPSHOT)

    snapshots.forEach(({ trading }) => {
      Object.values(MarketId).forEach((marketId) => {
        Object.values(StatsInstrumentType).forEach((instrumentType) => {
          if (marketId in trading && instrumentType in trading[marketId]!) {
            aggregateTrading[marketId][instrumentType].fees +=
              trading[marketId]![instrumentType]!.fees
            aggregateTrading[marketId][instrumentType].volume +=
              trading[marketId]![instrumentType]!.volume
            aggregateTrading[marketId][instrumentType].premiums +=
              trading[marketId]![instrumentType]!.premiums
            aggregateTrading[marketId][instrumentType].trades +=
              trading[marketId]![instrumentType]!.trades
          }
        })
      })
    })

    const lastTvl = deepCopy(snapshots[snapshots.length - 1].tvl)

    return {
      timestamp: +timestamp,
      trading: aggregateTrading,
      tvl: lastTvl,
    }
  })
}

export const filterTradingSnapshots = (
  snapshots: DailySnapshot[],
  marketFilter: StatsMarketFilter,
  instrumentFilter: StatsInstrumentFilter,
  isCumulative: boolean
) => {
  let instrumentSnapshots: {
    timestamp: number
    trading: Partial<Record<StatsInstrumentType, DailySnapshotTradingData>>
  }[]

  if (marketFilter === StatsMarketFilter.ALL) {
    // all markets
    instrumentSnapshots = snapshots.map((snapshot) => {
      const emptyInstrumentData = Object.values(StatsInstrumentType).reduce(
        (dict, type) => ({
          ...dict,
          [type]: {
            volume: 0,
            premiums: 0,
            fees: 0,
            trades: 0,
          },
        }),
        {} as Record<StatsInstrumentType, DailySnapshotTradingData>
      )

      return {
        timestamp: snapshot.timestamp,
        trading: Object.values(snapshot.trading).reduce((dict, instrumentsDict) => {
          Object.entries(instrumentsDict).forEach(([_instrumentType, instrumentData]) => {
            const instrumentType = _instrumentType as StatsInstrumentType
            if (instrumentType in dict) {
              dict[instrumentType]!.fees += instrumentData.fees
              dict[instrumentType]!.premiums += instrumentData.premiums
              dict[instrumentType]!.trades += instrumentData.trades
              dict[instrumentType]!.volume += instrumentData.volume
            }
          })
          return dict
        }, emptyInstrumentData),
      }
    })
  } else {
    instrumentSnapshots = snapshots.map((s) => ({
      timestamp: s.timestamp,
      trading: s.trading[marketFilter] ?? {},
    }))
  }

  // instrument filter
  if (instrumentFilter !== StatsInstrumentFilter.ALL) {
    instrumentSnapshots = instrumentSnapshots.map((s) => {
      const instrumentData = Object.values(StatsInstrumentType).reduce(
        (dict, type) => ({
          ...dict,
          [type]: {
            volume: 0,
            premiums: 0,
            fees: 0,
            trades: 0,
          },
        }),
        {} as Partial<Record<StatsInstrumentType, DailySnapshotTradingData>>
      )

      instrumentData[instrumentFilter] = s.trading[instrumentFilter]

      return {
        timestamp: s.timestamp,
        trading: instrumentData,
      }
    })
  }

  if (isCumulative) {
    instrumentSnapshots.forEach(({ trading }, idx) => {
      const prevSnapshot = instrumentSnapshots[idx - 1]?.trading
      if (!prevSnapshot) {
        return
      }
      Object.values(StatsInstrumentType).forEach((instrumentType) => {
        if (instrumentType in trading && instrumentType in prevSnapshot) {
          trading[instrumentType]!.fees += prevSnapshot[instrumentType]!.fees
          trading[instrumentType]!.volume += prevSnapshot[instrumentType]!.volume
          trading[instrumentType]!.premiums += prevSnapshot[instrumentType]!.premiums
          trading[instrumentType]!.trades += prevSnapshot[instrumentType]!.trades
        }
      })
    })
  }

  // trades
  const tradesSnapshots = instrumentSnapshots.map((s) => {
    return {
      timestamp: s.timestamp,
      ...Object.entries(s.trading).reduce(
        (dict, [key, val]) => ({
          ...dict,
          [key]: val.trades,
        }),
        {} as Record<StatsInstrumentType, number>
      ),
    }
  })

  // fees
  const feesSnapshots = instrumentSnapshots.map((s) => {
    return {
      timestamp: s.timestamp,
      ...Object.entries(s.trading).reduce(
        (dict, [key, val]) => ({
          ...dict,
          [key]: val.fees,
        }),
        {} as Record<StatsInstrumentType, number>
      ),
    }
  })

  // volume
  const volumeSnapshots = instrumentSnapshots.map((s) => {
    return {
      timestamp: s.timestamp,
      ...Object.entries(s.trading).reduce(
        (dict, [key, val]) => ({
          ...dict,
          [key]: val.volume,
        }),
        {} as Record<StatsInstrumentType, number>
      ),
    }
  })

  return { tradesSnapshots, feesSnapshots, volumeSnapshots }
}

export const getTotalTradingStats = (
  snapshots: DailySnapshot[]
): { trades: number; volume: number; premiums: number; fees: number } => {
  let trades = 0,
    volume = 0,
    premiums = 0,
    fees = 0
  snapshots.forEach((snapshot) => {
    Object.values(snapshot.trading).forEach((markets) => {
      Object.values(markets).forEach((instrument) => {
        trades += instrument.trades
        volume += instrument.volume
        premiums += instrument.premiums
        fees += instrument.fees
      })
    })
  })
  return { trades, volume, premiums, fees }
}
